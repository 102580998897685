import { Textfield, Filefield, Request } from "../../forms/ViewModelFormTypes";

export class CreatePlantDocumentViewModel {
  public visibleView: boolean = false;
  public goBack: boolean = false;

  public createDocumentButtonDisabled: boolean = true;

  public createDocumentRequest: Request = { loading: false, error: "" };

  public createdDocument: string = "";

  public name: Textfield = { label: "Name", value: "", error: "" };
  public file: Filefield = { label: "Datei", value: null, error: "" };
  public description: Textfield = {
    label: "Beschreibung",
    value: "",
    error: ""
  };
}
