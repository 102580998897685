import { ICreatePlantDocumentPresenter } from "../controllers/CreatePlantDocumentController";
import { CreatePlantDocumentViewModel } from "../vms/CreatePlantDocumentViewModel";
import { DocumentForm } from "../forms/DocumentForm";
import { FormResponse } from "@/forms/FormResponse";
import { PlantDocument, PlantDocumentPackage } from "../services/PlantService";

export class CreatePlantDocumentPresenter
  implements ICreatePlantDocumentPresenter {
  public documentForm: DocumentForm;

  public constructor(
    public plantId: string,
    plantDocument: PlantDocument,
    private vm: CreatePlantDocumentViewModel
  ) {
    this.documentForm = new DocumentForm(this.vm, this.setDocumentFormValid);

    if (!!plantDocument) {
      this.initFields(plantDocument);
    }
  }

  public setDocumentFormValid(context: any, valid: boolean) {
    context.createDocumentButtonDisabled = !valid;
  }

  public set visibleView(visible: boolean) {
    this.vm.visibleView = visible;
    if (!visible) {
      setTimeout(() => (this.vm.goBack = true), 300);
    }
  }

  public set createDocumentResponse(response: FormResponse<string>) {
    this.vm.createDocumentRequest = response;

    if (!response.loading && !response.error) {
      this.vm.createdDocument = response.data;
    }
  }

  public get plantDocument(): PlantDocumentPackage {
    return {
      name: this.vm.name.value,
      description: this.vm.description.value,
      file: this.vm.file.value!
    };
  }

  private initFields(plantDocument: PlantDocument) {
    this.documentForm.setFieldValue("name", plantDocument.name);
    this.documentForm.setFieldValue("description", plantDocument.description);
  }
}
