import { DocumentForm } from "../forms/DocumentForm";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { IPlantService, PlantDocumentPackage } from "../services/PlantService";
import { FormResponse } from "@/forms/FormResponse";

export class CreatePlantDocumentController {
  public constructor(
    private isOperator: boolean,
    private presenter: ICreatePlantDocumentPresenter,
    private plantService: IPlantService
  ) {}

  public mounted() {
    this.presenter.visibleView = true;
  }

  public closeButtonClicked(): void {
    this.presenter.visibleView = false;
  }

  public fileChanged(file: any): void {
    this.presenter.documentForm.setFieldValue("file", file);
  }

  public nameChanged(name: string): void {
    this.presenter.documentForm.setFieldValue("name", name);
  }

  public descriptionChanged(description: string): void {
    this.presenter.documentForm.setFieldValue("description", description);
  }

  public createDocumentButtonClicked() {
    const request = this.plantService.uploadPlantDocument(
      this.presenter.plantId,
      this.isOperator,
      this.presenter.plantDocument
    );
    FormRequestHandler.handle(
      request,
      response => (this.presenter.createDocumentResponse = response),
      "update-plant-failed"
    );
  }
}

export interface ICreatePlantDocumentPresenter {
  visibleView: boolean;
  documentForm: DocumentForm;
  plantId: string;
  plantDocument: PlantDocumentPackage;
  createDocumentResponse: FormResponse<string>;
}
