



















import Vue from "vue";
import Component from "vue-class-component";

@Component<Fileinput>({
  props: {
    chips: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default() {
        return { label: "Label", value: null, error: "" };
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    filled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: ""
    },
    suffix: {
      type: String,
      default: ""
    }
  }
})
export default class Fileinput extends Vue {}
