








































































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import RequestButton from "@/components/basic/RequestButton.vue";

import Textarea from "@/components/form/Textarea.vue";
import Textfield from "@/components/form/Textfield.vue";
import Fileinput from "@/components/form/Fileinput.vue";

import { CreatePlantDocumentViewModel } from "@/plant/vms/CreatePlantDocumentViewModel";
import { CreatePlantDocumentPresenter } from "@/plant/presenters/CreatePlantDocumentPresenter";
import { CreatePlantDocumentController } from "@/plant/controllers/CreatePlantDocumentController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { PlantGraphQLService } from "../services/PlantGraphQLService";
import { PlantDocument } from "../services/PlantService";

@Component<CreatePlantDocumentView>({
  components: {
    Card,
    Container,
    Column,
    Row,
    RequestButton,

    Textarea,
    Textfield,
    Fileinput
  },
  watch: {
    "vm.goBack"() {
      this.vm.visibleView = false;
      this.$emit("closed");
      this.$destroy();
    },
    "vm.createdDocument"() {
      this.vm.visibleView = false;
      this.$emit("finished");
      this.$destroy();
    }
  },
  props: {
    id: String,
    existingDocument: {
      type: Object,
      default() {
        return null;
      }
    },
    isOperator: {
      type: Boolean,
      default: false
    }
  }
})
export default class CreatePlantDocumentView extends Vue {
  private vm = new CreatePlantDocumentViewModel();
  private controller?: CreatePlantDocumentController;
  private existingDocument!: PlantDocument;

  private initController(): CreatePlantDocumentController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new CreatePlantDocumentController(
      this.$props.isOperator,
      new CreatePlantDocumentPresenter(
        this.$props.id,
        this.existingDocument,
        this.vm
      ),
      new PlantGraphQLService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted();
  }
}
